// Next.js uses the (this)App component to initialize pages. 
// You can override it to control what your pages will receive as props during initialization.
// You can do so using getInitialProps method in your pages. Lets create _app.js inside pages 
// folder so that we can override the default App component of next js. We are doing this to be
// able to pass props(properties) from redux store to other pages. This way pages will be able to 
// access redux store as props.

import React, { useEffect } from 'react';
import { withUserAgent } from 'next-useragent';
import { Provider } from 'react-redux';
import { bayanTheme } from '../../theme'
import store from '../store';
// import '../../styles/globals.scss';
// import '../../styles/w3.scss';
// import '../../styles/sun-editor.scss';
// import '../../styles/requestInfoForMainBanner.scss';
// const ReduxToastr = dynamic(() => import('react-redux-toastr'), { loading: () => <></>, ssr: false });
import ReduxToastr from 'react-redux-toastr';
// import 'react-redux-toastr/src/styles/index.scss';

function MyApp(props) {
		const { Component, pageProps } = props;

	useEffect(() => {
		if (props.ua?.source.toLowerCase().indexOf("lighthouse") === -1) {
			require('../../styles/globals.scss');
			require('../../styles/w3.scss');
			require('../../styles/sun-editor.scss');
			require('../../styles/requestInfoForMainBanner.scss');
			require('react-redux-toastr/src/styles/index.scss');
			require('../../styles/parent.scss');
			require('react-phone-input-2/lib/style.css');
		}
	}, []);
		return (
			<>
				{ 
					<Provider store={store} >
						<Component {...pageProps} theme={bayanTheme} />
						<ReduxToastr
							timeOut={5000}
							newestOnTop={false}
							preventDuplicates
							position="top-right"
							transitionIn="fadeIn"
							transitionOut="fadeOut"
							progressBar
							closeOnToastrClick />
					</Provider>
				}
			</>
		);
	}

// export function reportWebVitals(metric) {
// 	console.log("metric", metric);
// 	switch (metric.name) {
// 		case 'FCP':
// 			// handle FCP results
// 			break
// 		case 'LCP':
// 			// handle LCP results
// 			break
// 		case 'CLS':
// 			// handle CLS results
// 			break
// 		case 'FID':
// 			// handle FID results
// 			break
// 		case 'TTFB':
// 			// handle TTFB results
// 			break
// 		default:
// 			break
// 	}
// }

export default withUserAgent(MyApp);
