const bayan = {
	bayanTheme: {
		bgColor: 'white',
		gradient: 'rgba(46, 82, 102, 0.1)',
		'primaryColor': '#2E5266',
		'secondaryColor': '#e1bf42',
	}
}

export const bayanTheme = { ...bayan }

